import { gsap } from "gsap";


// tan banner bg
const header = document.getElementById("header")
let contactBtnWrapper = document.getElementById("contact-btn-wrapper")
let screenWidth = window.innerWidth
const ogHeader = header.innerHTML
let newHeader

let mobileNav = document.getElementById("mobile-nav")
let navBtn = document.getElementById("mobile-nav-btn")
let navEx = document.getElementById("nav-menu-exit-btn")

if(navEx){
    navEx.addEventListener("click", function() {
        if(mobileNav.classList.contains("mobile-nav-active")) {
            mobileNav.classList.remove("mobile-nav-active")
            
        }   
    })
    }
    if(navBtn){
    navBtn.addEventListener("click", function() {
        if(!mobileNav.classList.contains("mobile-nav-active")) {
        mobileNav.classList.add("mobile-nav-active")
        
        }
    })
    } 

let wrapperWidth = contactBtnWrapper.offsetWidth
let wrapperHeight = contactBtnWrapper.offsetHeight

let wrapperWidthOp = window.innerWidth - contactBtnWrapper.offsetWidth
let wrapperHeightOp = window.innerHeight - contactBtnWrapper.offsetHeight
screenWidth = window.innerWidth

newHeader = ogHeader

if(contactBtnWrapper && !contactBtnWrapper.classList.contains("no-bg") && screenWidth > 1280 ){

    wrapperWidth = contactBtnWrapper.offsetWidth
    wrapperHeight = contactBtnWrapper.offsetHeight
    
    wrapperWidthOp = window.innerWidth - contactBtnWrapper.offsetWidth
    wrapperHeightOp = window.innerHeight - contactBtnWrapper.offsetHeight

    if(window.location.pathname === "/"){
        header.innerHTML = `<div id="animated-bg" class="bg-tan-light absolute right-0 top-0 " style="height:0px; width:${wrapperWidth}px; z-index: -1;"></div>` + newHeader
    } else {
        header.innerHTML = `<div id="animated-bg" class="bg-tan-lightest absolute left-0 top-0 " style="height:0px; width:${wrapperWidthOp}px; z-index: -2;"></div>` + newHeader
    }
    

    gsap.to("#animated-bg", {height: window.innerHeight, duration: 5, ease: "expo"})

    
}

window.addEventListener("resize",debounce(function(e){
    screenWidth = window.innerWidth

    newHeader = ogHeader
    contactBtnWrapper = document.getElementById("contact-btn-wrapper")
    if(contactBtnWrapper && !contactBtnWrapper.classList.contains("no-bg") && screenWidth > 1280 ){

        wrapperWidth = contactBtnWrapper.offsetWidth
        wrapperHeight = contactBtnWrapper.offsetHeight
        
        wrapperWidthOp = window.innerWidth - contactBtnWrapper.offsetWidth
        wrapperHeightOp = window.innerHeight - contactBtnWrapper.offsetHeight
    
        if(window.location.pathname === "/"){
            header.innerHTML = `<div id="animated-bg" class="bg-tan-light absolute right-0 top-0 " style="height:0px; width:${wrapperWidth}px; z-index: -2;"></div>` + newHeader
        } else {
            header.innerHTML = `<div id="animated-bg" class="bg-tan-lightest absolute left-0 top-0 " style="height:0px; width:${wrapperWidthOp}px; z-index: -3;"></div>` + newHeader
        }
        
    
        gsap.to("#animated-bg", {height: window.innerHeight, duration: 5, ease: "expo"})

        
    }

    // mobile nav functionality
    mobileNav = document.getElementById("mobile-nav")
    navBtn = document.getElementById("mobile-nav-btn")
    navEx = document.getElementById("nav-menu-exit-btn")

    if(navEx){
    navEx.addEventListener("click", function() {
        if(mobileNav.classList.contains("mobile-nav-active")) {
            mobileNav.classList.remove("mobile-nav-active")
            
        }   
    })
    }
    if(navBtn){
    navBtn.addEventListener("click", function() {
        if(!mobileNav.classList.contains("mobile-nav-active")) {
        mobileNav.classList.add("mobile-nav-active")
        
        }
    })
    } 
}));




// debounce for time
function debounce(func){
    var timer;
    return function(event){
      if(timer) clearTimeout(timer);
      timer = setTimeout(func,300,event);
    };
  }

// two col bg
const columnsRight = document.querySelectorAll(".two-col-bg-right")
const columnsLeft = document.querySelectorAll(".two-col-bg-left")
const bgRight = document.querySelectorAll(".bg-right")
const bgLeft = document.querySelectorAll(".bg-left")

if(columnsRight){
    for (let i = 0; i < columnsRight.length; i++) {
        const element = columnsRight[i];
        const bg = bgRight[i]

        gsap.to(bg, {height: element.clientHeight, duration: 3, ease: "expo"})
    }
}

if(columnsLeft){
    for (let i = 0; i < columnsLeft.length; i++) {
        const element = columnsLeft[i];
        const bg = bgLeft[i]
        
        gsap.to(bg, {height: element.clientHeight, duration: 3, ease: "expo"})
    }
}

// banner swiper
const bannerSwiper = new Swiper('.banner-swiper', {
    
    direction: 'horizontal',
    spaceBetween: 10,
    slidesPerView: 1,
    loop: true,
    autoplay: {
        disableOnInteraction: true,
        delay: 5000
    }
    
});

const bannerSwiperPrevButtons = document.querySelectorAll(".banner-slider-prev-btn")
const bannerSwiperNextButtons = document.querySelectorAll(".banner-slider-next-btn")

if(bannerSwiperPrevButtons.length){
    for (let i = 0; i < bannerSwiperPrevButtons.length; i++) {
        const el = bannerSwiperPrevButtons[i];
       
        el.addEventListener("click", () => {
            bannerSwiper.slidePrev()
        })
    }
}

if(bannerSwiperNextButtons.length){
    for (let i = 0; i < bannerSwiperNextButtons.length; i++) {
        const el = bannerSwiperNextButtons[i];
       
        el.addEventListener("click", () => {
            bannerSwiper.slideNext()
        })
    }
}

// two col swiper
const twoColSwiper = new Swiper('.two-col-swiper', {
    
    direction: 'horizontal',
    spaceBetween: 10,
    slidesPerView: 1,
    loop: true,
    autoplay: {
        disableOnInteraction: true,
        delay: 5000
    }
    
});

// entry swiper
const entrySwiper = new Swiper('.entry-swiper', {
    
    direction: 'horizontal',
    spaceBetween: 10,
    slidesPerView: 1,
    loop: true,
    breakpoints: {
        // when window width is >= 640px
        640: {
          slidesPerView: 3
        }
      }
    
});

const entrySwiperPrevButtons = document.querySelectorAll(".entry-slider-prev-btn")
const entrySwiperNextButtons = document.querySelectorAll(".entry-slider-next-btn")

if(entrySwiperPrevButtons.length){
    for (let i = 0; i < entrySwiperPrevButtons.length; i++) {
        const el = entrySwiperPrevButtons[i];
       
        el.addEventListener("click", () => {
            entrySwiper.slidePrev()
        })
    }
}

if(entrySwiperNextButtons.length){
    for (let i = 0; i < entrySwiperNextButtons.length; i++) {
        const el = entrySwiperNextButtons[i];
       
        el.addEventListener("click", () => {
            entrySwiper.slideNext()
        })
    }
}

// btn arrows
const btnArrowsRight = document.querySelectorAll(".btn-arrow-right")
const btnArrowsLeft = document.querySelectorAll(".btn-arrow-left")
const btnArrowsLeftWhite = document.querySelectorAll(".btn-arrow-left-white")
const allBtnArrows = document.querySelectorAll(".btn-arrow-container")

if(btnArrowsRight.length){
    for (let i = 0; i < btnArrowsRight.length; i++) {
        const el = btnArrowsRight[i];
        el.innerHTML = el.innerHTML + '<img src="/static/Vector-3.svg" class="inline-block arrow" style="transition: all .3s;">'
    }
}
if(btnArrowsLeft.length){
    for (let i = 0; i < btnArrowsLeft.length; i++) {
        const el = btnArrowsLeft[i];
        el.innerHTML = el.innerHTML + '<img src="/static/Vector-3.svg" class="inline-block arrow" style="transition: all .3s;">'
    }
}
if(btnArrowsLeftWhite.length){
    for (let i = 0; i < btnArrowsLeftWhite.length; i++) {
        const el = btnArrowsLeftWhite[i];
        el.innerHTML = el.innerHTML + '<img src="/static/arrow-white-long.svg" class="inline-block arrow" style="transition: all .3s;">'
    }
}

const basicArrows = document.querySelectorAll(".arrow")

if(basicArrows.length && allBtnArrows.length){
    
    for (let i = 0; i < basicArrows.length; i++) {
        const el = basicArrows[i];
        const container = allBtnArrows[i]
        
        container.addEventListener("mouseover", () => {
            el.style.transform = "translateX(10px)"
        })  
        container.addEventListener("mouseout", () => {
            el.style.transform = "translateX(0px)"
        })  

    }
}



// btn hover arrows
const arrowHoverBtns = document.querySelectorAll(".arrow-hover-btn")
const arrowHoverBtnArrows = document.querySelectorAll(".arrow-hover-btn-arrow")

if(arrowHoverBtns.length){
    for (let i = 0; i < arrowHoverBtns.length; i++) {
        const el = arrowHoverBtns[i];
        const elWidth = el.offsetWidth
        const elArrow = arrowHoverBtnArrows[i]
        el.style.width = elWidth + 'px'

        el.addEventListener("mouseover", () => {
            el.style.width = elWidth + 50 + 'px'
           
            elArrow.style.opacity = 1
        })
        el.addEventListener("mouseout", () => {
            el.style.width = elWidth + 'px'
           
            elArrow.style.opacity = 0
        })
    }
}

// date placeholder 
const datePicker = document.querySelector(".datepicker-input")
const datePickerPlaceholder = document.querySelector(".date-placeholder")

if(datePicker && datePickerPlaceholder){
    datePicker.addEventListener("click", () => {
        datePickerPlaceholder.classList.add("hidden")
    })
}